import { Auth } from "aws-amplify";
import axios from "axios";
import { useEffect, useState } from "react";

import { Table } from "..";

let serverUrl = process.env.REACT_APP_SERVER_URL;

export const Guests = () => {
  const [guests, setGuests] = useState([]);

  const [sortBy, setSortBy] = useState("date");

  const getToken = async () => {
    const data = await Auth.currentSession();

    return data.idToken.jwtToken;
  };

  useEffect(() => {
    getToken().then(function (token) {
      token = "Bearer " + token;
      axios
        .get(serverUrl + "guests/getall", {
          headers: {
            "X-Hemingway-Authorization": token,
          },
        })
        .then((guests) => {
          setGuests(
            guests.data.sort((a, b) => {
              return new Date(b.date) - new Date(a.date);
            })
          );
        });
    });
  }, []);

  const handleSortByChange = (event) => {
    const newSortBy = event.target.value;

    setSortBy(newSortBy);

    if (newSortBy === "date") {
      setGuests((prevGuests) =>
        prevGuests.sort((a, b) => {
          return new Date(b.date) - new Date(a.date);
        })
      );
    } else {
      setGuests((prevGuests) =>
        prevGuests.sort((a, b) => {
          a = a[newSortBy].toLowerCase();
          b = b[newSortBy].toLowerCase();

          return a.localeCompare(b);
        })
      );
    }
  };

  const headers = ["Invited By", "Name", "Date", "Phone", "Email"];

  const rows = guests.map((guest) => [
    guest.added_by,
    guest.name,
    guest.date,
    guest.phone_number,
    guest.email,
  ]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "29px",
        }}
      >
        <h3>Guests</h3>

        <div style={{ display: "flex", alignItems: "center" }}>
          <h4 style={{ marginRight: "8px", marginBottom: 0 }}>Sort By:</h4>

          <select value={sortBy} onChange={handleSortByChange}>
            <option value="added_by">Invited By</option>
            <option value="name">Name</option>
            <option value="date">Date</option>
            <option value="phone_number">Phone</option>
            <option value="email">Email</option>
          </select>
        </div>
      </div>

      <Table headers={headers} rows={rows} />
    </>
  );
};
