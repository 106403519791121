import { Amplify, Auth,Storage } from "aws-amplify";
import Spinner from "react-bootstrap/Spinner";
import Tab from "react-bootstrap/Tab";
import { useEffect, useState } from "react";

import {
  Calendar,
  Clubs,
  Guests,
  Invoices,
  Login,
  Messages,
  Notifications,
  Reports,
  SideNav,
  Store,
  Users,
  MemberOfTheMonth,
  PartnerSpotlight,
} from "./components";

Amplify.configure({
  // Your existing Auth configuration
  Auth: {
    userPoolId: process.env.REACT_APP_USER_POOL_ID, // Your UserPool ID
    region: process.env.REACT_APP_AWS_REGION, // Your AWS Region
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID, // Your WebClient ID
    identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL, // OPTIONAL - Amazon Cognito Identity Pool ID
  },
  // Additional configuration for Storage
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_S3_BUCKET, // REQUIRED -  S3 Bucket Name
      region: process.env.REACT_APP_AWS_REGION, // OPTIONAL -  S3 Bucket Region, default to the region specified in Auth
      identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL, // OPTIONAL - Amazon Cognito Identity Pool ID
    }
  }
});

const checkForAdmin = (groups) => {
  if (!groups) return false;

  for (let group of groups) {
    if (group === "admin") {
      return true;
    }
  }

  return false;
};

const getToken = async () => {
  const data = await Auth.currentSession().catch((error) => {});

  if (data) {
    if (checkForAdmin(data.idToken.payload["cognito:groups"])) {
      return data.idToken.jwtToken;
    } else {
      return null;
    }
  }
};

function App() {
  const [token, setToken] = useState(null);
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [signInIsLoading, setSignInIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedClub, setSelectedClub] = useState(undefined);

  useEffect(() => {
    const setupToken = async () => {
      setPageIsLoading(true);

      const result = await getToken();
      setToken(result);

      setPageIsLoading(false);
    };

    setupToken();
  }, [setToken]);

  const signIn = async (username, password) => {
    setSignInIsLoading(true);

    try {
      const result = await Auth.signIn(username, password);

      if (
        checkForAdmin(
          result.signInUserSession.idToken.payload["cognito:groups"]
        )
      ) {
        setToken(result.signInUserSession.accessToken.jwtToken);
        setError(null);
      } else {
        setError({ message: "user not an admin" });
      }
    } catch (err) {
      setError(err);
    }

    setSignInIsLoading(false);
  };

  const signOut = async () => {
    setPageIsLoading(true);

    await Auth.signOut();
    setToken(null);

    setPageIsLoading(false);
  };

  if (pageIsLoading) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  if (!token) {
    return (
      <Login handleSignIn={signIn} isLoading={signInIsLoading} error={error} />
    );
  }

  // else
  return (
    <Tab.Container defaultActiveKey="Calendar">
      <div className="d-flex">
        <SideNav handleSignOut={signOut} setSelectedClub={setSelectedClub} />

        <div style={{ width: "calc(100% - 307px)" }}>
          <Tab.Content style={{ padding: "51px 43px 18px 49px" }}>
            <Tab.Pane eventKey="Calendar">
              <Calendar />
            </Tab.Pane>

            <Tab.Pane eventKey="Notifications">
              <Notifications />
            </Tab.Pane>

            <Tab.Pane eventKey="Messages">
              <Messages />
            </Tab.Pane>

            <Tab.Pane eventKey="Reports">
              <Reports />
            </Tab.Pane>

            <Tab.Pane eventKey="Guests">
              <Guests />
            </Tab.Pane>

            <Tab.Pane eventKey="Users">
              <Users />
            </Tab.Pane>

            <Tab.Pane eventKey="Clubs">
              <Clubs
                selectedClub={selectedClub}
                setSelectedClub={setSelectedClub}
              />
            </Tab.Pane>

            <Tab.Pane eventKey="Store">
              <Store />
            </Tab.Pane>

            <Tab.Pane eventKey="Invoices">
              <Invoices />
            </Tab.Pane>

            <Tab.Pane eventKey="Member Of The Month">
              <MemberOfTheMonth />
            </Tab.Pane>

            <Tab.Pane eventKey="Partner Spotlight">
              <PartnerSpotlight Storage={Storage} />
            </Tab.Pane>
          </Tab.Content>
        </div>
      </div>
    </Tab.Container>
  );
}

export default App;
