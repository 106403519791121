import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import { Auth } from "aws-amplify";

import {Button, FounderIcon } from "../..";

import styles from "./styles.module.css";
import axios from "axios";
const serverUrl = process.env.REACT_APP_SERVER_URL;

export const Username = (props) => {
  const { user } = props;
  const [guestPasses, setGuestPasses] = useState(user.guest_passes);

  const [userModalIsOpen, setUserModalIsOpen] = useState(false);
  
  const getToken = async () => {
    const data = await Auth.currentSession();

    return data.idToken.jwtToken;
  };

  const openUserModal = () => {
    setUserModalIsOpen(true);
  };

  const closeUserModal = () => {
    setUserModalIsOpen(false);
  };

  function createGiftRequest(username){

    return {
      userToGift:username,
      ammount:1
    }

  }


  

  const handleGiftPress = async (e, key) => {

    const token =  await getToken();
    axios.post(serverUrl+ "admin/giftGuestPass", 
      createGiftRequest(user.cognito_username)
    , {
      headers: {
        "X-Hemingway-Authorization": "Bearer " + token,
      },
    });

    setGuestPasses(guestPasses  ? + guestPasses + 1 : 1)
  };

  const handleresetPress = async (e, key) => {

    const token =  await getToken();
    axios.post(serverUrl+ "/cognito/user/reset_password", 
      {"userName":user.cognito_username,
      "password":"TheHadden4U#"
      
      }
    , {
      headers: {
        "X-Hemingway-Authorization": "Bearer " + token,
      },
    });

    
  };


  const cigarProfiles = user.cigar_profiles?.map((cigar, index) => (
    <div key={index} className={styles.attributes}>
      <span>
        <b>Gauge:</b> {cigar.gauge}"
      </span>
      <span>
        <b>Length:</b> {cigar.length}"
      </span>
      <span>
        <b>Note:</b> {cigar.note}
      </span>
      <span>
        <b>Wrapper:</b> {cigar.wrapper}
      </span>
      <span>
        <b>Shape:</b> {cigar.shape}
      </span>
    </div>
  ));

  const beverageProfiles = user.beverage_profiles?.map((beverage, index) => (
    <div key={index} className={styles.attributes}>
      <span>
        <b>Category:</b> {beverage.category}
      </span>
      <span>
        <b>Type:</b> {beverage.type}
      </span>
      <span>
        <b>Note:</b> {beverage.note}
      </span>
    </div>
  ));

  return (
    <>
      <span className={styles.username} onClick={openUserModal}>
        {user.name}
      </span>

      <Modal
        show={userModalIsOpen}
        onHide={closeUserModal}
        dialogClassName={styles.modal}
      >
        <Modal.Header closeButton />

        <Modal.Body className={styles.modalBody}>
          <div className={styles.userInfo}>
            <span className={styles.userInfoHeader}>{user.name}</span>

            {user.status_icon === 1 && (
              <span>
                <FounderIcon /> Founder
              </span>
            )}

            <span className={styles.memberSince}>
              Member Since: {user.member_since}
            </span>

            <span className={styles.email}>{user.email}</span>

            <span className={styles.phone}>
              {user.phone_number || "No Phone Number"}
            </span>

            <span className={styles.phone}>
              guest passes - {guestPasses || "0"}
            </span>
            <br></br>
            <span className={styles.phone}>
            <Button
          style={{ width: "180px", height: "35px", marginTop: 0 }}
          onClick={handleGiftPress}
        >
          Gift Guest Pass
        </Button>
        <Button
          style={{ width: "180px", height: "35px", marginTop: 5 }}
          onClick={() => { if (window.confirm('Are you sure you wish to Reset '+ user.email + " password to TheHadden4U# ?")) handleresetPress() } }
        >
          Password Reset
        </Button>

            </span>
        
          </div>

          <div className={styles.profileContainer}>
            <span className={styles.profileHeader}>Cigar Profiles</span>

            {cigarProfiles?.length ? cigarProfiles : "None"}
          </div>

          <div className={styles.profileContainer}>
            <span className={styles.profileHeader}>Beverage Profiles</span>

            {beverageProfiles?.length ? beverageProfiles : "None"}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
