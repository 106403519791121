import Spinner from "react-bootstrap/Spinner";
import { useState } from "react";

import { Button, Input } from "..";
import haddenLogo from "../../assets/images/haddenLogo.png";

import styles from "./styles.module.css";

export const Login = (props) => {
  const { handleSignIn, isLoading, error } = props;

  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");

  const handleUsernameChange = (event) => {
    setUser(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPass(event.target.value);
  };

  const handleSignInClick = (e) => {
    e.preventDefault();

    handleSignIn(user, pass);
  };

  return (
    <form className={styles.loginForm} onSubmit={handleSignInClick}>
      <div className={styles.innerForm}>
        <img
          className={styles.logo}
          src={haddenLogo}
          alt="Hadden Logo"
          width={301}
          height={232}
        />

        <Input
          value={user}
          onChange={handleUsernameChange}
          placeholder="Username"
          disabled={isLoading}
        />

        <Input
          value={pass}
          onChange={handlePasswordChange}
          type="password"
          placeholder="Password"
          disabled={isLoading}
        />

        {error && <p>{error?.message}</p>}

        <Button type="submit" disabled={isLoading}>
          {isLoading ? <Spinner style={{ marginTop: "3px" }} /> : "Sign In"}
        </Button>
      </div>
    </form>
  );
};
