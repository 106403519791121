import { Auth } from "aws-amplify";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { parseInt } from "lodash";
import { useEffect, useState } from "react";

import { Button, Table } from "..";

import styles from "./styles.module.css";

export const Messages = () => {
  const [messages, setMessages] = useState([]);
  const [edit, setEdit] = useState(null);
  const [state, setState] = useState({});
  const [trigger, setTrigger] = useState(true);
  const [show, setShow] = useState(false);
  const [user, setUser] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getToken = async () => {
    const data = await Auth.currentSession();

    return data.idToken.jwtToken;
  };

  const getUser = async () => {
    const data = await Auth.currentSession();

    const cogId = data.idToken.payload["cognito:username"];
    const email = data.idToken.payload.email;
    setUser({ cogID: cogId, email: email });
  };

  useEffect(() => {
    getUser();

    getToken().then(function (token) {
      token = "Bearer " + token;
      let serverUrl = process.env.REACT_APP_SERVER_URL;
      axios
        .get(serverUrl + "message/get_all", {
          headers: {
            "X-Hemingway-Authorization": token,
          },
        })
        .then((messages) => {
          setMessages(messages.data.sort((a, b) => b.timestamp - a.timestamp));
        });
    });
  }, [trigger]);

  const handleChange = (e, key) => {
    setState((prevState) => {
      return {
        ...prevState,
        [key]: e.target.value,
      };
    });
  };

  const handleSave = async (index) => {
    getToken().then(function (token) {
      token = "Bearer " + token;
      let serverUrl = process.env.REACT_APP_SERVER_URL;
      const message = { ...state };
      message.timestamp = messages[index].timestamp;
      message.cognito_username_poster = messages[index].cognito_username_poster;
      message.email_poster = messages[index].email_poster;

      axios
        .post(serverUrl + "message/update", message, {
          headers: {
            "X-Hemingway-Authorization": token,
          },
        })
        .then((resp) => {
          setTrigger(!trigger);
          setEdit(null);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const handleDelete = async (index) => {
    getToken().then(function (token) {
      token = "Bearer " + token;
      let serverUrl = process.env.REACT_APP_SERVER_URL;
      const timestamp = messages[index].timestamp;
      axios
        .post(
          serverUrl + "message/delete",
          { timestamp: timestamp },
          {
            headers: {
              "X-Hemingway-Authorization": token,
            },
          }
        )
        .then((resp) => {
          setTrigger(!trigger);
          setEdit(null);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const handleNewMessage = async (index) => {
    getToken().then(function (token) {
      token = "Bearer " + token;
      let serverUrl = process.env.REACT_APP_SERVER_URL;
      const message = { ...state };
      message.timestamp = Date.now();
      message.cognito_username_poster = user.cogId;
      message.email_poster = user.email;
      axios
        .post(serverUrl + "message/update", message, {
          headers: {
            "X-Hemingway-Authorization": token,
          },
        })
        .then((resp) => {
          setTrigger(!trigger);
          setEdit(null);
          handleClose();
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  useEffect(() => {
    if (edit === null) return;
    setState({
      title: messages[edit].title,
      body: messages[edit].body,
    });
  }, [edit, messages]);

  const columnWidths = ["15%", "10%", undefined, "15%", "5%"];

  const headers = ["User", "Title", "Body", "Time", "Actions"];

  const rows = messages?.map((message, index) => {
    const time = new Date(parseInt(message.timestamp));

    return [
      message.email_poster,
      edit === index ? (
        <input
          value={state.title}
          onChange={(e) => {
            handleChange(e, "title");
          }}
        />
      ) : (
        message.title
      ),
      edit === index ? (
        <textarea
          value={state.body}
          onChange={(e) => {
            handleChange(e, "body");
          }}
        />
      ) : (
        message.body
      ),
      time.toLocaleString(),
      <div
        style={{
          display: "flex",
          gap: "10px",
          marginLeft: "5px",
          marginRight: "5px",
        }}
      >
        {!(edit === index) ? (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              setEdit(index);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-pencil-square"
              viewBox="0 0 16 16"
            >
              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
              <path
                fillRule="evenodd"
                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
              />
            </svg>
          </div>
        ) : (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleSave(index);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-save2"
              viewBox="0 0 16 16"
            >
              <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v4.5h2a.5.5 0 0 1 .354.854l-2.5 2.5a.5.5 0 0 1-.708 0l-2.5-2.5A.5.5 0 0 1 5.5 6.5h2V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z" />
            </svg>
          </div>
        )}
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleDelete(index);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-trash"
            viewBox="0 0 16 16"
          >
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
            <path
              fillRule="evenodd"
              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
            />
          </svg>
        </div>
      </div>,
    ];
  });

  return (
    <>
      <div
        style={{
          marginBottom: "29px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3 style={{ marginBottom: 0 }}>Messages</h3>

        <Button
          style={{ width: "120px", height: "35px", marginTop: 0 }}
          onClick={handleShow}
        >
          New Message
        </Button>
      </div>

      <Table columnWidths={columnWidths} headers={headers} rows={rows} />

      <Modal show={show} onHide={handleClose} dialogClassName={styles.modal}>
        <Modal.Header closeButton>
          <Modal.Title>New Message</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label>Title</label>
            <input
              onChange={(e) => {
                handleChange(e, "title");
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              paddingTop: "20px",
              flexDirection: "column",
            }}
          >
            <label>Body</label>
            <textarea
              onChange={(e) => {
                handleChange(e, "body");
              }}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className={styles.modalFooter}>
            <Button style={{ width: "150px" }} onClick={handleClose}>
              Close
            </Button>

            <Button style={{ width: "150px" }} onClick={handleNewMessage}>
              Create Message
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
