import styles from "./styles.module.css";

export const Input = (props) => {
  const { style, inline, label, value, onChange, type, placeholder, disabled,checked } =
    props;

  const typeIsCheckbox = type === "checkbox";

  const inputElement = (
    <input
      id={label}
      className={styles.input}
      value={value}
      onChange={onChange}
      checked={checked}
      type={type || "text"}
      placeholder={placeholder}
      disabled={disabled}
    />
  );

  return (
    <div
      style={style}
      className={`${styles.labelAndInput}${inline ? ` ${styles.inline}` : ""}`}
    >
      {label && (
        <label
          htmlFor={label}
          className={`${styles.label} ${typeIsCheckbox ? styles.checkbox : ""}`}
        >
          {typeIsCheckbox && inputElement}
          <span>{label}</span>
        </label>
      )}

      {!typeIsCheckbox && inputElement}
    </div>
  );
};
