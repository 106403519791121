import { Auth } from "aws-amplify";
import { Button, Input } from "..";
import axios from "axios";
import { useEffect, useState } from "react";
import { Dropdown } from 'react-bootstrap';
import styles from "./styles.module.css";
import { Table } from "..";
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { v4 as uuidv4 } from 'uuid';

const serverUrl = process.env.REACT_APP_SERVER_URL;

const getToken = async () => {
    const data = await Auth.currentSession();

    return "Bearer " + data.idToken.jwtToken;
};

export const PartnerSpotlight = (props) => {
    const [motm, setMotm] = useState([]);
    const [show, setShow] = useState([]);
    const [users, setUsers] = useState([]);
    const [items, setItems] = useState([]);
    const [monthDetails, setMonthDetails] = useState({});
    const [tempUserSelections, setTempUserSelections] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [selectedImage, setSelectedImage] = useState(null); // Added state for selected image
    const [urls, setUrls] = useState({})

    const handleFileUpload = async (file, monthNumber) => {
        try {

            const uniqueFileName = `${uuidv4()}/${file.name}`;
            const result = await props.Storage.put(uniqueFileName, file, {
                level: 'public', // Adjust the access level as needed
                contentType: file.type,
            });

            const getFileUrl = async (key) => {
                try {
                    const fileUrl = await props.Storage.get(key, { level: 'public' }); // Ensure the level is correct
                    return fileUrl;
                } catch (error) {
                    console.error('Error getting file URL:', error);
                    return '';
                }
            };

            // Update the logo field in the motm state with the S3 key of the uploaded file
            setMotm((prevMotm) => {
                return prevMotm.map((item) => {
                    if (item.entry_id === monthNumber) {
                        return { ...item, logo: result.key, isModified: true };
                    }
                    return item;
                });
            });

            setLoading(false);
            console.log('File uploaded successfully:', result);
        } catch (error) {
            console.error('Error uploading file:', error);
            setError('Error uploading file. Please try again.');
            setLoading(false);
        }
    };

    const fetchMotm = () => {
        setLoading(true);
        setError("");
        getToken().then((token) => {
            axios
                .get(`${serverUrl}psl/getAll`, {
                    headers: {
                        "X-Hemingway-Authorization": token,
                    },
                })
                .then((response) => {
                    const fetchedData = response.data;

                    // Reset states to ensure fresh start
                    const newTempUserSelections = {};
                    const newMonthDetails = {};

                    fetchedData.forEach(item => {
                        newTempUserSelections[item.entry_id] = item.user_id;
                        newMonthDetails[item.entry_id] = item.details || ""; // Ensure details is a string
                    });

                    setMotm(fetchedData);
                    setTempUserSelections(newTempUserSelections);
                    setMonthDetails(newMonthDetails);

                    setLoading(false);
                })
                .catch((error) => {
                    console.error("Failed to fetch members of the month", error);
                    setError("Failed to load members of the month.");
                    setLoading(false);
                });
        });
    };

    useEffect(() => {
        const getUsers = async () => {
            const token = await getToken();

            const usersResponse = await axios.get(serverUrl + "users", {
                headers: {
                    "X-Hemingway-Authorization": "Bearer " + token,
                },
            });

            setUsers(
                usersResponse.data.filter((user) => user.name)
            );
        };

        getUsers();
    }, []);

    useEffect(() => {
        fetchMotm(); // This will fetch data for the newly selected year
    }, []);

    useEffect(() => {
        const fetchUrl = async (item) => {
            if (item && item.logo) {
                try {
                    const url = await props.Storage.get(item.logo);
                    setUrls((prevFileUrl) => {
                        return { ...prevFileUrl, [item.entry_id]: url };
                    });
                } catch (error) {
                    console.error("Error fetching file URL:", error);
                }
            }
        };

        motm.map((item) => {
            fetchUrl(item);
        })

    }, [motm]);

    const headers = ["Users", 'Details', 'Logo', 'Company Name', 'Go Live Date', ''];

    const handleMemberSelection = (monthNumber, selectedOptions) => {
        setMotm((prevMotm) => {
            return prevMotm.map((item) => {
                if (item.entry_id === monthNumber) {
                    // Assuming you want to store just the user IDs in the members field
                    const updatedMembers = selectedOptions.map(option => option.value);
                    return { ...item, members: updatedMembers, isModified: true };
                }
                return item;
            });
        });
    };
    const handleSave = async () => {
        setLoading(true);
        setError("");

        try {
            const token = await getToken(); // Retrieve the auth token

            // Construct the payload with adds, updates, and deletes
            const payload = {
                adds: motm.filter(item => item.new).map(({ new: _new, isModified: _mod, isDeleted: _del, ...keep }) => keep), // Remove control flags and keep the rest
                updates: motm.filter(item => !item.new && item.isModified).map(({ new: _new, isModified: _mod, isDeleted: _del, ...keep }) => keep),
                deletes: motm.filter(item => item.isDeleted).map(item => item.entry_id),
            };

            console.log('payload', payload)
            // Make the POST request to your batch endpoint
            await axios.post(`${serverUrl}psl/batch`, payload, {
                headers: {
                    "X-Hemingway-Authorization": token, // Make sure your server is expecting this header
                },
            });

            // On success, you may want to refresh the data or notify the user
            setLoading(false);

            // refresh the list after saving
            fetchMotm();
        } catch (error) {
            console.error("Failed to save changes", error);
            setError("Failed to save changes. Please try again.");
            setLoading(false);
        }
    };

    const extractFilename = (url) => {
        // Extract the part of the URL before the first "?" character
        const pathname = url.split('?')[0];

        // Decode the pathname to handle URL-encoded characters
        const decodedPathname = decodeURIComponent(pathname);

        // Extract the filename, which is after the last "/" character in the decoded pathname
        const filename = decodedPathname.split('/').pop();

        return filename;
    };
    const handleAddNew = () => {

        setMotm((prevMotm) => {
            {
                const newEntry = {
                    new: true,
                    entry_id: prevMotm.length + 1,
                    user_id: "",
                    details: "",
                    logo: "",
                    go_live_date: ""
                }
                return [...prevMotm, newEntry]
            }
        })
    }

    const deleteEntry = (monthNumber) => {
        setMotm((prevMotm) => {
            return prevMotm.map((item) => {
                // If it's the item to delete, check if it's new or existing
                if (item.entry_id === monthNumber) {
                    // If it's a new item (doesn't exist in the backend yet), remove it directly
                    if (item.new) {
                        return null; // Mark for removal, will be filtered out
                    } else {
                        // For existing items, mark as deleted instead of removing
                        return { ...item, isDeleted: true };
                    }
                }
                // For items not being deleted, return them as they are
                return item;
            }).filter(item => item !== null); // Remove any items marked for removal
        });
    };


    // Map the months to rows, filtering members by the selected year and month
    const rows = motm.map((item) => {
        const monthNumber = item.entry_id;


        const Option = (props) => {
            const {
                children,
                className,
                cx,
                getStyles,
                isDisabled,
                isFocused,
                isSelected,
                innerRef,
                innerProps,
            } = props;
            return (
                <div
                    ref={innerRef}
                    css={getStyles('option', props)}
                    className={cx(
                        {
                            option: true,
                            'option--is-disabled': isDisabled,
                            'option--is-focused': isFocused,
                            'option--is-selected': isSelected,
                        },
                        className
                    )}
                    {...innerProps}
                >
                    {children}
                </div>
            );
        };

        if (item.isDeleted) {
            return null; // This should prevent the item from being rendered
        }

        return [
            <div style={{ padding: '10px' }}>

                <Select
                    isMulti
                    options={users.map(user => ({ value: user.cognito_username, label: user.name }))}
                    styles={{
                        option: (base) => ({
                            ...base,
                            backgroundColor: 'white',
                            color: 'black',
                            height: '100%',
                        }),
                    }}
                    value={users.filter(user => item.members?.includes(user.cognito_username)).map(user => ({ value: user.cognito_username, label: user.name }))}
                    onChange={(selectedOptions) => handleMemberSelection(item.entry_id, selectedOptions)}
                />

            </div>
            ,
            <Form.Group className="mb-3" controlId={`exampleForm.ControlTextarea1-${monthNumber}`} style={{ padding: '10px' }}>
                <Form.Control
                    onChange={(event) => {
                        setMotm((prevMotm) => {
                            const updatedMotm = prevMotm.map((motmItem) => {
                                if (motmItem.entry_id === monthNumber) {
                                    return { ...motmItem, details: event.target.value, isModified: true }
                                }
                                return motmItem
                            })
                            return updatedMotm
                        })
                    }}
                    value={item.details}
                    as="textarea" rows={2} />
            </Form.Group>,

            <div style={{ padding: '10px' }}>
                <>
                    <Form.Group controlId="formFile" value={item.logo} className="mb-3">
                        <Form.Control type="file"
                            onChange={(event) => {
                                const file = event.target.files[0];
                                if (file) {
                                    handleFileUpload(file, item.entry_id);
                                }
                            }} />
                    </Form.Group>

                    {item.logo && (
                        <div className="mt-2">
                            <strong>Uploaded File:</strong>
                            <a href={urls[item.entry_id]} target="_blank" rel="noopener noreferrer">
                                {item.logo}
                            </a>
                        </div>
                    )}

                </>
            </div>,
            <Form.Group className="mb-3" controlId={`companyName-${monthNumber}`} style={{ padding: '10px' }}>
                <Form.Control
                    type="text"
                    placeholder="Enter company name"
                    value={item.partner_name || ''}
                    onChange={(event) => {
                        setMotm((prevMotm) => {
                            return prevMotm.map((motmItem) => {
                                if (motmItem.entry_id === monthNumber) {
                                    return { ...motmItem, partner_name: event.target.value, isModified: true };
                                }
                                return motmItem;
                            });
                        });
                    }}
                />
            </Form.Group>,
            <div style={{ padding: '10px' }}>
                <Form.Group controlId="date" className="mb-3">
                    <Form.Control type="date" value={item.go_live_date}
                        onChange={(event) => {
                            setMotm((prevMotm) => {
                                const updatedMotm = prevMotm.map((motmItem) => {
                                    if (motmItem.entry_id === monthNumber) {
                                        return { ...motmItem, go_live_date: event.target.value, isModified: true }
                                    }
                                    return motmItem
                                })
                                return updatedMotm
                            })
                        }} />
                </Form.Group>
            </div>
            ,

            <div style={{ padding: '10px' }}>
                <Button style={{ width: '100px' }} onClick={() => deleteEntry(monthNumber)}>Delete</Button>
            </div>
        ];
    }).filter(row => row !== null);
    const columnWidths = ['30%', '30%', '30%', '30%', '5%', '5%'];
    return (
        <>
            {error && <p className="text-danger">{error}</p>}

            <Table columnWidths={columnWidths} headers={headers} rows={rows} />
            <Button loading={loading} style={{ width: '100px', marginRight: '20px' }} onClick={() => handleAddNew()}>Add New</Button>
            <Button loading={loading} style={{ width: '100px' }} onClick={() => handleSave()}>Save</Button>
        </>
    );
};



