// handler functions that all calendar components will use

import { getListItemSecondaryActionClassesUtilityClass } from "@mui/material";
import { Auth } from "aws-amplify";
import axios from "axios";

let serverUrl = process.env.REACT_APP_SERVER_URL;

function toISOStringWithoutMillis(date, shift = -10) {
    let newDate = new Date(date.getTime());

    newDate.setUTCHours(newDate.getUTCHours() + 0);
    return newDate.getTime();
}

const getToken = async () => {
    const data = await Auth.currentSession();

    return data.idToken.jwtToken;
};

function setTimezoneOffset(date, offsetMinutes) {
    const originalOffset = date.getTimezoneOffset();
    const newDate = new Date(date.getTime() + (offsetMinutes - originalOffset) * 60 * 1000);
    return newDate;
}


const getUsername = async () => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        const username = user.username; // This is the Cognito username

        return username;
    } catch (error) {
        console.error('Error getting the username:', error);
        return null; // Handle the error as needed
    }
};
// this function handles the creation of an event
export const handleCreateEvent = async (
    selectInfo,
    title,
    repeat,
    fromDate,
    toDate,
    days,
    sendNotificationNow,
    details,
    link,
    sponsor,
    email,
    phone
) => {
    let token = await getToken();
    token = "Bearer " + token;

    let calendarApi = selectInfo.view.calendar;
    calendarApi.unselect(); // clear date selection
    const username = await getUsername()

    if (title) {

        let start = new Date(selectInfo.startStr)
        let end = new Date(selectInfo.endStr)

        if (start.getTimezoneOffset() === 0) {
            start = new Date(start.getTime() + 6 * 60 * 60000);
            end = new Date(end.getTime() + 6 * 60 * 60000);
        }


        start = start.getTime()
        end = end.getTime()

        const eventData = {
            start: start,
            end: end,
            note: title,
            username: username,
            sendNotificationNow: sendNotificationNow,
            event_details: details,
            registration_link: link,
            event_sponsor: sponsor,
            email: email,
            phone: phone,
            daySelected: selectInfo.start,

        };

        if (repeat) {
            Object.assign(eventData, {
                fromDate: fromDate,
                toDate: toDate,
                days: days,

            });

            await axios.post(
                serverUrl + "events/addMany",
                eventData,
                {
                    headers: {
                        "X-Hemingway-Authorization": token,
                    },
                }
            );
        } else {
            await axios.post(
                serverUrl + "events/add",
                eventData,
                {
                    headers: {
                        "X-Hemingway-Authorization": token,
                    },
                }
            );
        }
    }
};

const convertTo12HourFormat = (time) => {
    const [hour, minute] = time.split(':').map(Number);
    const period = hour >= 12 ? 'pm' : 'am';
    const convertedHour = hour > 12 ? hour - 12 : (hour === 0 ? 12 : hour);
    return `${convertedHour}:${String(minute).padStart(2, '0')} ${period}`;
}
export const handleDeleteEvent = async (eventId, repeat, seriesId) => {
    let token = await getToken();
    token = "Bearer " + token;

    if (eventId) {
        await axios.post(
            serverUrl + "events/remove",
            {
                event_id: eventId
            },
            {
                headers: {
                    "X-Hemingway-Authorization": token,
                },
            }
        );
    }

    // If you also want to support bulk deletion based on repeating events,
    // ensure your backend can handle this via the event ID as well.
    if (repeat) {
        await axios.post(
            serverUrl + "events/removeMany",
            {
                series_id: seriesId
            },
            {
                headers: {
                    "X-Hemingway-Authorization": token,
                },
            }
        );
    }
};

// this function handles the deletion of an event
export const handleEditEvent = async (
    selectInfo,
    title,
    repeat,
    fromDate,
    toDate,
    days,
    startTime,
    endTime,
    oldStart,
    oldEnd,
    oldTitle,
    sendNotificationNow,
    date,
    event_id,
    seriesId, start, end, details, meta, link,
    sponsor, phone, email
) => {
    let token = await getToken();
    token = "Bearer " + token;

    const username = await getUsername()
    if (repeat) {
        // Delete the series with the given seriesId
        await axios.post(
            serverUrl + "events/removeMany",
            {
                series_id: seriesId
            },
            {
                headers: {
                    "X-Hemingway-Authorization": token,
                },
            }
        );

        let localStart = new Date(start.toISOString().split('T')[0] + "T" + startTime);
        let localEnd = new Date(start.toISOString().split('T')[0] + "T" + endTime);

        const eventData = {
            start: localStart.getTime(),
            end: localEnd.getTime(),
            note: title,
            fromDate: fromDate,
            toDate: toDate,
            days: days,
            event_details: details,
            sendNotificationNow: sendNotificationNow,
            registration_link: link,
            username: username,
            daySelected: selectInfo.start,

            attendees: meta.attendees,
            phone: phone ? phone : meta.phone,
            email: email ? email : meta.email,
            event_sponsor: sponsor ? meta.event_sponsor : meta.event_sponsor,

        };

        await axios.post(
            serverUrl + "events/addMany",
            eventData,
            {
                headers: {
                    "X-Hemingway-Authorization": token,
                },
            }
        );
    } else {
        // If not a repeating event, just update the single event
        let localStart = new Date(start.toISOString().split('T')[0] + "T" + startTime);
        let localEnd = new Date(start.toISOString().split('T')[0] + "T" + endTime);

        // if (localStart.getTimezoneOffset() === 0) {
        //     localStart = new Date(localStart.getTime() + 12 * 60 * 60000);
        //     localEnd = new Date(localEnd.getTime() + 12 * 60 * 60000);
        // }

        await axios.post(
            serverUrl + "events/update",
            {
                event_id,
                start: localStart.getTime(),
                end: localEnd.getTime(),
                note: title,
                event_details: details,
                series_id: '',
                registration_link: link,
                username: username,

                attendees: meta.attendees,
                phone: phone ? phone : meta.phone,
                email: email ? email : meta.email,
                event_sponsor: sponsor ? meta.event_sponsor : meta.event_sponsor,
            },
            {
                headers: {
                    "X-Hemingway-Authorization": token,
                },
            }
        );
    }
};
