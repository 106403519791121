import { Auth } from 'aws-amplify';
import axios from 'axios';
import { useEffect, useState } from 'react';
import process from 'process';

import { Button } from '..';
import { StoreModal } from './StoreModal';

import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
  KeyboardSensor,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const serverUrl = process.env.REACT_APP_SERVER_URL;

export const Store = () => {
  const [trigger, setTrigger] = useState(true);
  const [items, setItems] = useState([]);
  const [originalItems, setOriginalItems] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [storeModalIsOpen, setStoreModalIsOpen] = useState(false);

  const [activeId, setActiveId] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleOpenStoreModal = () => setStoreModalIsOpen(true);
  const handleCloseStoreModal = () => setStoreModalIsOpen(false);

  const getToken = async () => {
    const data = await Auth.currentSession();
    return data.idToken.jwtToken;
  };

  useEffect(() => {
    getToken().then(function (token) {
      token = 'Bearer ' + token;
      axios
        .get(serverUrl + 'store/skus', {
          headers: {
            'X-Hemingway-Authorization': token,
          },
        })
        .then((response) => {
          const sortedItems = response.data.sort((a, b) => a.order - b.order);
          setItems(sortedItems);
          setOriginalItems(sortedItems);
          setHasUnsavedChanges(false);
        });
    });
  }, [trigger]);

  const handleCreateItem = async (newItem) => {
    setLoading(true);
    getToken().then(function (token) {
      token = 'Bearer ' + token;

      axios
        .post(
          serverUrl + 'store/sku/create',
          { ...newItem, sku: crypto.randomUUID(), order: items.length },  
          {
            headers: {
              'X-Hemingway-Authorization': token,
            },
          }
        )
        .then((resp) => {
          console.log(resp);
          setLoading(false);
          setTrigger(!trigger);
          handleCloseStoreModal();
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response.data);
          setError(err.message + '. Details: ' + err.response.data);
          setLoading(false);
        });
    });
  };

  const handleDeleteItem = async (itemToRemove) => {
    if (
      window.confirm(
        `Are you sure you want to delete the item '${itemToRemove.name}'`
      )
    ) {
      getToken().then(function (token) {
        token = 'Bearer ' + token;

        axios
          .delete(serverUrl + 'store/sku/remove', {
            headers: {
              'X-Hemingway-Authorization': token,
            },
            data: { sku: itemToRemove.sku },
          })
          .then((resp) => {
            setTrigger(!trigger);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }
  };

  const onDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item.sku === active.id);
        const newIndex = items.findIndex((item) => item.sku === over.id);
        const newItems = arrayMove(items, oldIndex, newIndex);

        // Update the order field
        const updatedItems = newItems.map((item, index) => ({
          ...item,
          order: index,
        }));

        setHasUnsavedChanges(true);

        return updatedItems;
      });
    }

    setActiveId(null);
  };

  const saveOrderToServer = (updatedItems) => {
    getToken().then((token) => {
      token = 'Bearer ' + token;
  
      axios
        .post(
          serverUrl + 'store/skus/reorder',
          {
            skus: updatedItems.map((item) => ({
              sku: item.sku,
              name: item.name,
              price: item.price,
              desc: item.desc,
              category: item.category,
              icon: item.icon,
              order: item.order,
            })),
          },
          {
            headers: {
              'X-Hemingway-Authorization': token,
            },
          }
        )
        .then(() => {
          console.log('SKUs updated successfully');
          setHasUnsavedChanges(false);
          setOriginalItems(updatedItems);
        })
        .catch((err) => {
          console.error('Error updating SKUs:', err);
        });
    });
  };
  

  const headers = ['Name', 'Description', 'Price', ''];

  const SortableItem = ({ item }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({ id: item.sku });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
      opacity: isDragging ? 0.5 : 1,
      cursor: 'grab',
    };

    return (
      <tr ref={setNodeRef} style={style} {...attributes} {...listeners}>
        <td>{item.name}</td>
        <td>{item.desc}</td>
        <td>{item.price}</td>
        <td>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              handleDeleteItem(item);
            }}
          >
            {/* ... SVG for trash icon ... */}
          </div>
        </td>
      </tr>
    );
  };

  return (
    <>
      <div
        style={{
          marginBottom: '29px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h3 style={{ marginBottom: 0 }}>Store</h3>

        <div style={{ display: 'flex', alignItems: 'center',gap:'20px' }}>
          <StoreModal
            isOpen={storeModalIsOpen}
            closeModal={handleCloseStoreModal}
            handleCreateItem={handleCreateItem}
            loading={loading}
            error={error}
            openElement={
              <Button
                style={{ width: '120px', height: '35px', marginTop: 0 }}
                onClick={handleOpenStoreModal}
              >
                New Item
              </Button>
            }
          />
          {hasUnsavedChanges && (
            <Button
              style={{ width: '120px', height: '35px', marginTop: 0 }}
              onClick={() => {
                setItems(originalItems);
                setHasUnsavedChanges(false);
              }}
            >
              Cancel
            </Button>
          )}

          {hasUnsavedChanges && (
            <Button
              style={{ width: '120px', height: '35px', marginTop: 0 }}
              onClick={() => {
                saveOrderToServer(items);
              }}
            >
              Save Order
            </Button>
          )}
        </div>
      </div>

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={(event) => setActiveId(event.active.id)}
        onDragEnd={onDragEnd}
      >
        <SortableContext
          items={items.map((item) => item.sku)}
          strategy={verticalListSortingStrategy}
        >
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                {headers.map((header) => (
                  <th
                    key={header}
                    style={{ borderBottom: '1px solid #ddd', padding: '8px' }}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {items.map((item) => (
                <SortableItem key={item.sku} item={item} />
              ))}
            </tbody>
          </table>
        </SortableContext>
      </DndContext>
    </>
  );
};
