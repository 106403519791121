import styles from "./styles.module.css";

export const Table = (props) => {
  const { className, columnWidths = [], headers, rows } = props;

  const headerElements = headers.map((header, index) => (
    <th key={index}>{header}</th>
  ));

  const rowElements = rows.map((row, rowIndex) => (
    <tr key={rowIndex}>
      {row.map((cell, columnIndex) => (
        <td key={columnIndex} style={{ width: columnWidths[columnIndex] }}>
          {cell}
        </td>
      ))}
    </tr>
  ));

  return (
    <table className={`${styles.table} ${className ? className : ""}`}>
      <thead>
        <tr>{headerElements}</tr>
      </thead>

      <tbody>{rowElements}</tbody>
    </table>
  );
};
