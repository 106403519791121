import { Auth } from "aws-amplify";
import { Button, Input } from "..";
import axios from "axios";
import { useEffect, useState } from "react";
import { Dropdown } from 'react-bootstrap';
import styles from "./styles.module.css";
import { Table } from "..";
import Form from 'react-bootstrap/Form';

const serverUrl = process.env.REACT_APP_SERVER_URL;

const getToken = async () => {
    const data = await Auth.currentSession();

    return "Bearer " + data.idToken.jwtToken;
};

export const MemberOfTheMonth = () => {
    const [motm, setMotm] = useState([]);
    const [show, setShow] = useState([]);
    const [users, setUsers] = useState([]);
    const [items, setItems] = useState([]);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Default to current year
    const [monthDetails, setMonthDetails] = useState({});
    const [tempUserSelections, setTempUserSelections] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const currentYear = new Date().getFullYear();
    const yearRange = Array.from({ length: 10 }, (v, i) => currentYear - i); // Last 10 years

    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const fetchMotm = () => {
        setLoading(true);
        setError("");
        getToken().then((token) => {
            axios
                .get(`${serverUrl}motm/getAll`, {
                    headers: {
                        "X-Hemingway-Authorization": token,
                    },
                })
                .then((response) => {
                    const fetchedData = response.data;
                    const filteredData = fetchedData.filter(item => item.year === selectedYear);

                    // Reset states to ensure fresh start
                    const newTempUserSelections = {};
                    const newMonthDetails = {};

                    filteredData.forEach(item => {
                        newTempUserSelections[item.month] = item.user_id;
                        newMonthDetails[item.month] = item.details || ""; // Ensure details is a string
                    });

                    setMotm(filteredData);
                    setTempUserSelections(newTempUserSelections);
                    setMonthDetails(newMonthDetails);

                    setLoading(false);
                })
                .catch((error) => {
                    console.error("Failed to fetch members of the month", error);
                    setError("Failed to load members of the month.");
                    setLoading(false);
                });
        });
    };




    useEffect(() => {
        const getUsers = async () => {
            const token = await getToken();

            const usersResponse = await axios.get(serverUrl + "users", {
                headers: {
                    "X-Hemingway-Authorization": "Bearer " + token,
                },
            });

            setUsers(
                usersResponse.data.filter((user) => user.name)
            );
        };

        getUsers();
    }, []);

    useEffect(() => {
        fetchMotm(); // This will fetch data for the newly selected year
    }, [selectedYear]);


    const columnWidths = ['25%', '25%', '50%'];

    const headers = ["Month", "User", 'Details', ''];


    const handleMemberSelection = (monthNumber, userId) => {
        const updatedSelections = { ...tempUserSelections, [monthNumber]: userId };
        console.log(`Updating selections for month ${monthNumber} to user ID ${userId}`, updatedSelections);
        setTempUserSelections(updatedSelections);
    };

    const saveAllChanges = () => {
        setLoading(true);
        setError("");

        const updates = Object.keys(monthDetails).map(month => ({
            user_id: tempUserSelections[month],
            details: monthDetails[month] || "",
            month: parseInt(month, 10),
            year: selectedYear,
        }));

        // Ensure we have updates to send
        if (updates.length === 0) {
            setLoading(false);
            setError("No changes to save.");
            return;
        }

        getToken().then(token => {
            axios.post(`${serverUrl}motm/add`, updates, {
                headers: {
                    "X-Hemingway-Authorization": `${token}`,
                },
            })
                .then(() => {
                    console.log("Successfully updated all members of the month.");
                    fetchMotm(); // Optionally, refresh the data
                    setLoading(false);
                })
                .catch(error => {
                    console.error("Failed to bulk update members of the month", error);
                    setError(`Failed to bulk update members of the month. ${error.message}`);
                    setLoading(false);
                });
        });
    };


    // Map the months to rows, filtering members by the selected year and month
    const rows = months.map((month, index) => {
        const monthNumber = (index + 1)
        const selectedUserId = tempUserSelections[monthNumber]; // Directly use monthNumber to get the selected user ID
        const memberDetails = users.find(u => u.cognito_username === selectedUserId); // Find user details based on the selectedUserId
    
        return [
            month,
            <Dropdown>
                <Dropdown.Toggle variant="success" size="sm" className={styles.customDropdownToggle}>
                    {memberDetails ? memberDetails?.name : 'Select Member'}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {users.map(user => (
                        <Dropdown.Item key={user.cognito_username} onClick={() => handleMemberSelection(monthNumber, user.cognito_username)}>
                            {user.name}
                        </Dropdown.Item>

                    ))}
                </Dropdown.Menu>
            </Dropdown>,

            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Control
                    onChange={(event) => {
                        setMonthDetails({ ...monthDetails, [monthNumber]: event.target.value });
                    }}
                    value={monthDetails[monthNumber] || ''}
                    as="textarea" rows={2} />
            </Form.Group>

        ];
    });

    return (
        <>
            {error && <p className="text-danger">{error}</p>}

            <div
                style={{
                    marginBottom: "29px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <h3 style={{ marginBottom: 0 }}>Members of the Month</h3>

                {/* Year Dropdown */}
                <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic" className={styles.customDropdownToggle}>
                        {selectedYear}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {yearRange.map((year) => (
                            <Dropdown.Item key={year} onClick={() => setSelectedYear(year)}>
                                {year}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <Table columnWidths={columnWidths} headers={headers} rows={rows} />
            <div style={{ padding: '20px', display: 'flex', justifyContent: 'end' }}>
                <Button loading={loading} onClick={saveAllChanges}>Save All</Button>
            </div>

        </>
    );
};
