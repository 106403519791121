export const MessagesIcon = (props) => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.55482 2.82743e-05C11.493 0.0209595 14.5699 1.24479 16.111 3.60413C16.8541 4.74292 17.1929 6.17214 16.9038 7.49408C16.5669 9.03122 15.61 10.3518 14.3489 11.4121C13.233 12.3495 12.0078 13.1593 10.994 14.2012C10.2542 14.962 9.61316 15.8175 9.05717 16.7156C9.05717 16.7156 9.03362 16.7725 8.93747 16.8589C8.48614 17.2631 7.49059 16.7509 8.00145 15.9333C8.98718 14.3576 10.2542 12.9611 11.741 11.7876C13.0439 10.7594 14.5143 9.84034 15.2352 8.32806C15.6531 7.45091 15.835 6.45405 15.5498 5.47225C15.0625 3.79448 13.5175 2.56476 11.8809 1.92636C9.04867 0.82158 5.57604 1.1434 3.21865 2.99778C1.85943 4.06658 0.976391 5.83331 1.42576 7.53202C1.84765 9.12803 3.23566 10.3433 4.77411 11.0262C5.94233 11.5443 7.22372 11.7843 8.50249 11.7869C8.50249 11.7869 8.56332 11.7778 8.68695 11.8144C9.13697 11.9472 9.31685 12.6347 8.90411 12.9578C8.64312 13.1619 8.24085 13.1004 7.85885 13.0788C4.5818 12.8911 1.16739 11.2421 0.205858 8.03109C-0.255284 6.48938 0.0776543 4.75274 1.01106 3.42622C2.63323 1.12181 5.71863 -0.00651275 8.55482 2.82743e-05ZM5.8815 6.54824C6.24257 6.54824 6.53626 6.84194 6.53626 7.203C6.53626 7.56472 6.24257 7.85841 5.8815 7.85841C5.51978 7.85841 5.22675 7.56472 5.22675 7.203C5.22675 6.84194 5.51978 6.54824 5.8815 6.54824ZM8.50053 6.54824C8.86225 6.54824 9.15528 6.84194 9.15528 7.203C9.15528 7.56472 8.86225 7.85841 8.50053 7.85841C8.13946 7.85841 7.84577 7.56472 7.84577 7.203C7.84577 6.84194 8.13946 6.54824 8.50053 6.54824ZM11.1202 6.54824C11.4813 6.54824 11.775 6.84194 11.775 7.203C11.775 7.56472 11.4813 7.85841 11.1202 7.85841C10.7585 7.85841 10.4655 7.56472 10.4655 7.203C10.4655 6.84194 10.7585 6.54824 11.1202 6.54824Z"
        fill="white"
      />
    </svg>
  );
};
