import React from 'react';
import styles from './styles.module.css';

export const Button = (props) => {
  const { children, style, onClick, type, disabled, loading } = props;

  return (
    <button
      style={style}
      className={`${styles.button} ${loading ? styles.loading : ''}`}
      onClick={onClick}
      type={type}
      disabled={disabled || loading}
    >
      {loading ? (
        <div className={styles.spinner}></div> // This will display a spinner when loading
      ) : (
        children
      )}
    </button>
  );
};
