import Modal from "react-bootstrap/Modal";
import { useState } from "react";

import { Button, Input } from "../..";

import styles from "./styles.module.css";

const toBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => resolve(fileReader.result);
    fileReader.onerror = (error) => reject(error);
  });
};

export const StoreModal = (props) => {
  const {
    isOpen,
    closeModal,
    editMode,
    defaultValues,
    handleEditItem,
    handleCreateItem,
    openElement,
    error,
    loading

  } = props;

  const [values, setValues] = useState(
    (editMode && defaultValues) || {
      name: "",
      desc: "",
      price: "",
      category: "",
      icon: "",
    }
  );

  const [iconFileName, setIconFileName] = useState("");

  const handleInputChange = (e, key) => {
    setValues((prevValues) => {
      return {
        ...prevValues,
        [key]: e.target.value,
      };
    });
  };

  const handleIconChange = async (e) => {
    const iconFile = e.target.files[0];
    setIconFileName(iconFile.name);

    const iconBase64 = await toBase64(iconFile);

    setValues((prevValues) => {
      return {
        ...prevValues,
        icon: iconBase64,
      };
    });
  };

  return (
    <>
      {openElement}

      <Modal show={isOpen} onHide={closeModal} dialogClassName={styles.modal}>
        <Modal.Header closeButton />

        <Modal.Body>
          <h4>New Item</h4>

          <Input
            label="Name"
            value={values.name}
            onChange={(e) => handleInputChange(e, "name")}
          />

          <Input
            label="Description"
            value={values.desc}
            onChange={(e) => handleInputChange(e, "desc")}
          />

          <Input
            label="Price"
            value={values.price}
            onChange={(e) => handleInputChange(e, "price")}
          />

          <Input
            label="Thumbnail"
            title={iconFileName}
            onChange={handleIconChange}
            type="file"
          />

          {editMode ? (
            <Button
              style={{ width: "100%" }}
              onClick={() => handleEditItem && handleEditItem(values)}
            >
              Edit Item
            </Button>
          ) : (
            <Button
              loading={loading}
              style={{ width: "100%" }}
              onClick={() => handleCreateItem && handleCreateItem(values)}
            >
              Create Item
            </Button>
          )}
          <div style={{ color: "red", marginTop: "10px" }}>{error}</div>
        </Modal.Body>
      </Modal>
    </>
  );
};
