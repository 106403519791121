import {Auth} from "aws-amplify";
import axios from "axios";
import {useEffect, useState} from "react";

import {Table} from "..";

const serverUrl = process.env.REACT_APP_SERVER_URL;

const getToken = async () => {
    const data = await Auth.currentSession();

    return "Bearer " + data.idToken.jwtToken;
};

export const Invoices = () => {
    const [invoices, setInvoices] = useState([]);
    const [users, setUsers] = useState([]);
    const [items, setItems] = useState([]);

    const [editingId, setEditingId] = useState(undefined);
    const [statuses, setStatuses] = useState({});

    const fetchInvoices = () => {
        getToken().then((token) => {
            axios
                .get(serverUrl + "store/invoices", {
                    headers: {
                        "X-Hemingway-Authorization": token,
                    },
                })
                .then((invoices) => {
                    setInvoices(
                        invoices.data.sort((a, b) => {
                            const dateDiff =
                                new Date(b.timestamp * 1000) - new Date(a.timestamp * 1000);

                            if (a.invoice_status === b.invoice_status) {
                                return dateDiff;
                            } else if (a.invoice_status === "new") {
                                return -1;
                            } else if (b.invoice_status === "new") {
                                return 1;
                            } else if (a.invoice_status === "billed") {
                                return -1;
                            } else {
                                return 1;
                            }
                        })
                    );
                });
        });
    };

    useEffect(() => {
        fetchInvoices();
    }, []);

    useEffect(() => {
        getToken().then((token) => {
            axios
                .get(serverUrl + "users", {
                    headers: {
                        "X-Hemingway-Authorization": token,
                    },
                })
                .then((users) => {
                    setUsers(users.data);
                });
        });
    }, []);

    useEffect(() => {
        getToken().then((token) => {
            axios
                .get(serverUrl + "store/skus", {
                    headers: {
                        "X-Hemingway-Authorization": token,
                    },
                })
                .then((skus) => {
                    setItems(skus.data);
                });
        });
    }, []);

    useEffect(() => {
        setStatuses(
            invoices.reduce(
                (newStatuses, invoice) => ({
                    ...newStatuses,
                    [invoice.guid]: invoice.invoice_status,
                }),
                {}
            )
        );
    }, [invoices]);

    const updateStatus = (invoiceId, newStatus) => {
        getToken().then((token) => {
            axios
                .put(
                    serverUrl + "store/invoice/updateStatus",
                    {
                        guid: invoiceId,
                        invoice_status: newStatus,
                    },
                    {
                        headers: {
                            "X-Hemingway-Authorization": token,
                        },
                    }
                )
                .then(() => {
                    fetchInvoices();
                });
        });
    };

    const columnWidths = [undefined, undefined, "10%", "10%", "200px"];

    const headers = ["User", "Items", "Price", "Status", "Timestamp"];

    const rows = invoices.map((invoice) => {
        let price = 0;

        return [
            users.filter((user) => user.cognito_username === invoice.user_id)[0]
                ?.name,
            JSON.parse(invoice.skus)
                .map(({sku}) => {
                    const item = items.filter((item) => item.sku === sku.sku)[0];

                    price += (Number(item?.price.replaceAll("$", "")) || 0) * sku.amount;

                    return `${item?.name || "Invalid Item"} x${sku.amount}`;
                })
                .join(", "),
            `$${price}`,
            <div style={{display: "flex"}}>
                {editingId === invoice.guid ? (
                    <select
                        value={statuses[editingId]}
                        onChange={(e) =>
                            setStatuses((prevStatuses) => ({
                                ...prevStatuses,
                                [editingId]: e.target.value,
                            }))
                        }
                    >
                        <option value="new">new</option>
                        <option value="billed">billed</option>
                        <option value="paid">paid</option>
                    </select>
                ) : (
                    invoice.invoice_status
                )}
                {editingId === invoice.guid ? (
                    <div
                        style={{cursor: "pointer", marginLeft: "10px"}}
                        onClick={() => {
                            updateStatus(editingId, statuses[editingId]);
                            setEditingId(undefined);
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-save2"
                            viewBox="0 0 16 16"
                        >
                            <path
                                d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v4.5h2a.5.5 0 0 1 .354.854l-2.5 2.5a.5.5 0 0 1-.708 0l-2.5-2.5A.5.5 0 0 1 5.5 6.5h2V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z"/>
                        </svg>
                    </div>
                ) : (
                    <div
                        style={{cursor: "pointer", marginLeft: "10px"}}
                        onClick={() => {
                            setEditingId(invoice.guid);
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-pencil-square"
                            viewBox="0 0 16 16"
                        >
                            <path
                                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                            <path
                                fillRule="evenodd"
                                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                            />
                        </svg>
                    </div>
                )}
            </div>,
            new Date(invoice.timestamp * 1000).toLocaleString(),
        ];
    });

    return (
        <>
            <h3 style={{marginBottom: "29px"}}>Invoices</h3>

            <Table columnWidths={columnWidths} headers={headers} rows={rows}/>
        </>
    );
};
