import { Auth } from "aws-amplify";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { useEffect, useState } from "react";

import { Button, FounderIcon, Input, Table } from "..";
import { Username } from "./Username";

import styles from "./styles.module.css";

const serverUrl = process.env.REACT_APP_SERVER_URL;

const getToken = async () => {
  const data = await Auth.currentSession();
  return data.idToken.jwtToken;
};

export const Users = () => {
  const [users, setUsers] = useState([]);
  const [state, setState] = useState({
    name: "",
    email: "",
    phone_number: "",
    tempPassword: "",
    status_icon: false,
  });
  const [trigger, setTrigger] = useState(true);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showNewUserSuccessModal, setShowNewUserSuccessModal] = useState(false);
  const handleCloseNewUserSuccessModal = () =>
    setShowNewUserSuccessModal(false);
  const handleShowNewUserSuccessModal = () => setShowNewUserSuccessModal(true);

  const [newUserLoading, setNewUserLoading] = useState(false);
  const [newUserError, setNewUserError] = useState(undefined);

  useEffect(() => {
    const getUsers = async () => {
      const token = await getToken();

      const usersResponse = await axios.get(serverUrl + "users", {
        headers: {
          "X-Hemingway-Authorization": "Bearer " + token,
        },
      });

      setUsers(
        usersResponse.data
          .map((user) => ({
            ...user,
            unpaid_balance: user.unpaid_balance || false, // Ensure unpaid_balance property exists
          }))
          .sort((a, b) => {
            const founderDiff = (b.status_icon || 0) - (a.status_icon || 0);

            if (founderDiff === 0) {
              return a.name
                ?.trim()
                .toLowerCase()
                .localeCompare(b.name?.trim().toLowerCase());
            } else {
              return founderDiff;
            }
          })
      );
    };

    getUsers();
  }, [trigger]);

  const handleChange = (e, key) => {
    setState((prevState) => ({
      ...prevState,
      [key]: e.target.value,
    }));
  };

  function validatePassword(newPassword) {
    const minNumberofChars = 8;
    const regularExpression =
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9@#$%^&*]{8,}$/;
    if (newPassword.length < minNumberofChars) {
      alert("Password should contain at least 8 characters");
      return false;
    }
    if (!regularExpression.test(newPassword)) {
      alert(
        "Password should contain at least one number and one special character that's not !"
      );
      return false;
    }
    return true;
  }

  function createDeleteRequest(username) {
    return {
      userName: username,
    };
  }

  const handleDeletePress = async (username) => {
    const token = await getToken();
    axios
      .post(
        serverUrl + "cognito/user/delete",
        createDeleteRequest(username),
        {
          headers: {
            "X-Hemingway-Authorization": "Bearer " + token,
          },
        }
      )
      .then(() => {
        setTrigger(!trigger);
      });
  };

  const handleresetPress = async (user) => {
    const token = await getToken();
    axios.post(
      serverUrl + "/cognito/user/reset_password",
      {
        userName: user.cognito_username,
        password: "TheHadden4U#",
      },
      {
        headers: {
          "X-Hemingway-Authorization": "Bearer " + token,
        },
      }
    );
  };

  const handleNewUser = async () => {
    setNewUserLoading(true);
    setNewUserError(undefined);

    const token = await getToken();

    const user = { ...state };

    const isValidPassword = validatePassword(user.tempPassword);

    if (isValidPassword) {
      try {
        await axios.post(serverUrl + "cognito/user/add", user, {
          headers: {
            "X-Hemingway-Authorization": "Bearer " + token,
          },
        });

        setTrigger(!trigger);
        handleClose();
        handleShowNewUserSuccessModal();
      } catch (error) {
        console.log("Error on create user:", error);
        setNewUserError(error.message);
      }
    }

    setNewUserLoading(false);
  };

  // New function to handle unpaid balance toggle
  const handleToggleUnpaidBalance = async (user) => {
    const updatedUser = { ...user, unpaid_balance: !user.unpaid_balance };

    // Update the user in the backend
    try {
      const token = await getToken();

      await axios.post(
        serverUrl + "user/update",
        {
          ...updatedUser
        },
        {
          headers: {
            "X-Hemingway-Authorization": "Bearer " + token,
          },
        }
      );

      // Update the user in state
      setUsers((prevUsers) =>
        prevUsers.map((u) =>
          u.cognito_username === updatedUser.cognito_username
            ? updatedUser
            : u
        )
      );
    } catch (error) {
      console.error("Error updating unpaid balance:", error);
      // Optionally, show an error message to the user
    }
  };

  // Updated columnWidths and headers to include the new column
  const columnWidths = [
    "45px",
    undefined,
    undefined,
    undefined,
    "23%",
    "15%",
    "15%",
    "15%",
  ];

  const headers = [
    "",
    "Name",
    "Email",
    "Member Since",
    "Last Check In",
    "Unpaid Balance",
    "",
    "",
  ];

  const rows = users.map((user) => [
    user.status_icon === 1 && (
      <div style={{ marginTop: "2px" }}>
        <FounderIcon />
      </div>
    ),
    <Username user={user} />,
    user.email,
    user.member_since,
    user.checked_in ? new Date(user.checked_in).toLocaleString() : "Never",
    // New toggle for unpaid balance
    <input
      type="checkbox"
      checked={user.unpaid_balance}
      onChange={() => handleToggleUnpaidBalance(user)}
    />,
    // Delete button
    <Button
      style={{
        width: "100px",
        height: "35px",
        marginTop: 0,
        background: "#BB3F3F",
      }}
      onClick={() => {
        if (
          window.confirm(
            "Are you sure you wish to Delete " + user?.email + " ?"
          )
        )
          handleDeletePress(user.cognito_username);
      }}
    >
      Delete
    </Button>,
    // Password Reset button
    <Button
      style={{
        width: "180px",
        height: "35px",
        marginTop: 0,
        marginLeft: 5,
      }}
      onClick={() => {
        if (
          window.confirm(
            "Are you sure you wish to Reset " +
              user?.email +
              " password to TheHadden4U# ?"
          )
        )
          handleresetPress(user);
      }}
    >
      Password Reset
    </Button>,
  ]);

  return (
    <>
      <div
        style={{
          marginBottom: "29px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3 style={{ marginBottom: 0 }}>Users</h3>

        <Button
          style={{ width: "120px", height: "35px", marginTop: 0 }}
          onClick={handleShow}
        >
          New User
        </Button>
      </div>

      <Table
        className={styles.table}
        columnWidths={columnWidths}
        headers={headers}
        rows={rows}
      />

      <Modal show={show} onHide={handleClose} dialogClassName={styles.modal}>
        <Modal.Header closeButton />

        <Modal.Body>
          <h4>New User</h4>

          <Input
            label="Email"
            value={state.email}
            onChange={(e) => handleChange(e, "email")}
          />

          <Input
            label="Temporary Password"
            value={state.tempPassword}
            onChange={(e) => handleChange(e, "tempPassword")}
          />

          <p style={{ color: "red", textAlign: "center", overflow: "auto" }}>
            {newUserError}
          </p>

          <Button
            style={{ width: "100%" }}
            onClick={handleNewUser}
            disabled={newUserLoading}
          >
            {newUserLoading ? (
              <Spinner style={{ marginTop: "3px" }} />
            ) : (
              "Create User"
            )}
          </Button>
        </Modal.Body>
      </Modal>

      <Modal
        show={showNewUserSuccessModal}
        onHide={handleCloseNewUserSuccessModal}
        dialogClassName={styles.modal}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <p style={{ textAlign: "center" }}>
            New user: "{state.email}" successfully created
          </p>
        </Modal.Body>
        <Button
          style={{ width: "50%", alignSelf: "center", marginBottom: "20px" }}
          onClick={handleCloseNewUserSuccessModal}
        >
          OK
        </Button>
      </Modal>
    </>
  );
};
