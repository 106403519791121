import Nav from "react-bootstrap/Nav";

import {
  CalendarIcon,
  ClubsIcon,
  GuestsIcon,
  InvoicesIcon,
  LogOutIcon,
  MessagesIcon,
  NotificationsIcon,
  ReportsIcon,
  StoreIcon,
  UsersIcon,
  MemberOfTheMonthIcon,
  ParterSpotlightIcon,
} from "..";
import haddenLogo from "../../assets/images/haddenLogo.png";
import { NavItem } from "./NavItem";

import styles from "./styles.module.css";

export const SideNav = (props) => {
  const { handleSignOut, setSelectedClub } = props;

  return (
    <div className={styles.sideNav}>
      <Nav variant="pills" className="flex-column">
        <img
          className={styles.logo}
          src={haddenLogo}
          alt="Hadden Logo"
          width={174}
          height={134}
        />

        <NavItem label="Calendar" Icon={CalendarIcon} />

        <NavItem label="Notifications" Icon={NotificationsIcon} />

        <NavItem label="Messages" Icon={MessagesIcon} />

        <NavItem label="Reports" Icon={ReportsIcon} />

        <NavItem label="Guests" Icon={GuestsIcon} />

        <NavItem label="Users" Icon={UsersIcon} />

        <NavItem
          onClick={() => setSelectedClub(undefined)}
          label="Clubs"
          Icon={ClubsIcon}
        />

        <NavItem label="Store" Icon={StoreIcon} />

        <NavItem label="Invoices" Icon={InvoicesIcon} />
        <NavItem label="Member Of The Month" Icon={MemberOfTheMonthIcon} />
        <NavItem label="Partner Spotlight" Icon={ParterSpotlightIcon} />

        <NavItem
          style={{ marginTop: "30px" }}
          onClick={handleSignOut}
          label="Log Out"
          Icon={LogOutIcon}
        />
      </Nav>
    </div>
  );
};
