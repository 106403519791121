import { Auth } from "aws-amplify";
import axios from "axios";
import { useState } from "react";

import { Button, Input } from "..";

import styles from "./styles.module.css";

let serverUrl = process.env.REACT_APP_SERVER_URL;

const getToken = async () => {
  const data = await Auth.currentSession();

  return data.idToken.jwtToken;
};

export const Notifications = () => {
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  function sendNotification(e) {
    e.preventDefault();

    getToken().then(function (token) {
      token = "Bearer " + token;
      axios
        .post(
          serverUrl + "notification/send",
          {
            title: title,
            message: message,
          },
          {
            headers: {
              "X-Hemingway-Authorization": token,
            },
          }
        )
        .then((resp) => {
          alert("Notification sent successfull.");
        });
    });
  }

  return (
    <form className={styles.notificationForm} onSubmit={sendNotification}>
      <h3 className={styles.header}>
        Fill out the form below to send a Push Notification to all Users
      </h3>

      <Input
        label="Title"
        value={title}
        onChange={(event) => setTitle(event.target.value)}
        placeholder="Enter Title"
      />

      <Input
        label="Message"
        value={message}
        onChange={(event) => setMessage(event.target.value)}
        placeholder="Enter Message"
      />

      <Button type="submit">Send</Button>
    </form>
  );
};
