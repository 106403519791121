export const LogOutIcon = (props) => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00717 10.4811C6.29042 10.563 6.49177 10.8155 6.50542 11.1158C6.50542 12.4263 6.4986 13.7333 6.50542 15.0438C6.51225 15.3816 6.80915 15.682 7.15041 15.6888C9.77134 15.7058 12.3923 15.7366 15.0132 15.6888C15.3442 15.6785 15.6411 15.3885 15.648 15.0506C15.7299 10.6858 15.7299 6.3176 15.648 1.9528C15.6377 1.62177 15.3476 1.32487 15.0132 1.31463C12.3923 1.26686 9.77134 1.29757 7.15041 1.31463C6.80915 1.32146 6.50883 1.61154 6.50542 1.96987V5.8876C6.48153 6.39609 5.81948 6.72371 5.42361 6.38244C5.3144 6.28689 5.23591 6.15379 5.20861 6.01387C5.19837 5.95927 5.19837 5.94562 5.19837 5.89102V1.96645C5.2052 0.949479 6.07543 0.0280597 7.13335 0.0075837C9.75428 -0.00947962 12.3786 0.0075837 14.9995 0.0075837C16.0131 0.014409 16.9379 0.8744 16.9584 1.94256C16.9857 6.30736 16.9584 10.6687 16.9584 15.0335C16.9516 16.0505 16.0745 16.9719 15.0234 16.9924C12.4025 17.0095 9.77817 16.9924 7.15724 16.9924C6.13344 16.9856 5.2052 16.1119 5.19837 15.0335V11.1158C5.21885 10.7165 5.59083 10.3991 6.00717 10.4811ZM2.22935 7.84989H12.4093C12.5868 7.85671 12.6346 7.8806 12.7199 7.92838C13.3171 8.25258 12.9792 9.13305 12.4093 9.15694H2.22935L3.72751 10.6551C3.7514 10.6824 3.7787 10.7097 3.79918 10.7404C3.85719 10.8189 3.89473 10.911 3.9118 11.01C3.9937 11.4912 3.47156 11.8837 3.0518 11.7369C2.96308 11.7062 2.9153 11.6857 2.80609 11.5833L0.191991 8.96924L0.188579 8.96583C0.185166 8.96242 0.181753 8.959 0.178341 8.95559C0.16469 8.94194 0.157865 8.93511 0.140802 8.91464C-0.0127684 8.72012 -0.0434823 8.44369 0.0623103 8.22528C0.0930242 8.16385 0.106675 8.13314 0.188579 8.04782L2.80268 5.43372C2.91871 5.3211 3.06887 5.25626 3.22926 5.24261C3.25315 5.24261 3.2668 5.2392 3.33847 5.24602C3.55688 5.26991 3.75823 5.41324 3.85378 5.61118C4.01759 5.94903 3.72751 6.35514 3.72751 6.35514L2.22935 7.84989Z"
        fill="white"
      />
    </svg>
  );
};
