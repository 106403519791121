export const NotificationsIcon = (props) => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.61204 9.20361C2.61138 8.09533 2.60877 6.9864 2.61269 5.87812C2.64081 2.85079 5.22025 0.0719237 8.39731 0.00130776C8.43589 0.000653912 8.47447 0 8.51239 0C11.56 0.00980777 14.3801 2.64287 14.4108 5.87812V5.91539V9.21342C14.792 9.23172 15.1627 9.28599 15.5034 9.44161C16.8601 10.0647 17.4577 11.974 16.6025 13.2771C16.1337 13.9918 15.3085 14.4462 14.4448 14.4625C13.1247 14.471 11.8052 14.4736 10.4851 14.4736C10.489 15.1713 10.4478 15.8533 10.0437 16.3567C9.07209 17.5657 6.58091 17.0197 6.54561 15.1452C6.5443 14.9313 6.53253 14.6979 6.52926 14.4678C5.22287 14.4651 3.91647 14.4625 2.61008 14.4625C1.00291 14.4521 -0.370177 12.737 0.0901342 11.1135C0.397444 10.0268 1.4508 9.2108 2.61008 9.20361H2.61204ZM7.83239 14.4704C7.81735 14.8215 7.81539 15.1504 7.90431 15.3681C8.12008 15.8977 9.15382 15.8435 9.1669 15.1288C9.1682 14.9157 9.15905 14.6907 9.15513 14.473C8.71443 14.4723 8.27308 14.4717 7.83239 14.4704ZM13.1005 9.86334L13.0998 9.86073C13.0998 8.53603 13.1044 7.21132 13.0998 5.88662C13.0815 3.92899 11.7032 2.06617 9.82271 1.50517C7.11903 0.698967 3.93282 2.86845 3.92301 5.91735V9.20361H11.1337C11.1546 9.20426 11.1749 9.20557 11.1958 9.20622C11.7019 9.27946 11.9719 9.96731 11.5861 10.3367C11.4704 10.4472 11.3893 10.5061 11.1337 10.5185C8.28747 10.5185 5.44125 10.5002 2.59504 10.5185C1.6548 10.5362 0.941448 11.7674 1.55215 12.6063C1.79145 12.9358 2.18507 13.1398 2.59504 13.1476C4.07666 13.1568 5.55895 13.1627 7.04057 13.1653C7.10007 13.1516 7.16611 13.1463 7.24 13.1489C7.27661 13.1522 7.31257 13.1574 7.34723 13.1659C8.11877 13.1666 8.89032 13.1666 9.66186 13.1659C9.69979 13.1574 9.74032 13.1516 9.78348 13.1489C9.84363 13.1457 9.90313 13.1516 9.96067 13.1653C11.4495 13.1633 12.939 13.1574 14.4284 13.1476C15.4144 13.1287 16.1304 11.7366 15.3615 10.9265C15.1169 10.6695 14.7678 10.5205 14.4101 10.5185H13.7556C13.7033 10.5185 13.653 10.5133 13.6039 10.5028C13.1495 10.4283 13.1122 10.1275 13.1011 9.89211C13.1005 9.88231 13.1005 9.87315 13.1005 9.86334Z"
        fill="white"
      />
    </svg>
  );
};
