import Nav from "react-bootstrap/Nav";

import styles from "./styles.module.css";

export const NavItem = (props) => {
  const { style, onClick, label, Icon } = props;

  return (
    <Nav.Item>
      <Nav.Link
        style={style}
        className={styles.navLink}
        onClick={onClick}
        eventKey={label}
      >
        <Icon className={styles.navLinkIcon} />

        <span className={styles.navLinkText}>{label}</span>
      </Nav.Link>
    </Nav.Item>
  );
};
